export const environment = {
  production: true,
  endpoint: 'https://letsgo.casago.com/api',
  php_api: "https://pp.letsgo.casago.com",
  S3_BUCKET_URL: "https://casago-casa-app.s3.us-west-2.amazonaws.com",
  S3_BUCKET: "casago-casa-app",
  S3_ROOT: "prod/v2/",
  AWS_ACCESS_KEY_ID: "AKIAXOWZY22ABBPJ5MEU",
  AWS_SECRET_ACCESS_KEY: "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",
  AWS_REGION: "us-west-2",
  MODE: "desktop",
  firebaseConfigDesktop: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    vapidKey: ""
  }
};